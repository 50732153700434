import './App.css';
import Hero from './components/Hero/Hero';
import Join from './components/Join/Join';
import Testimonials from './components/Testimonials/Testimonials';

function App() {
  return (
    <div className="App">
          <Hero/>
          <Testimonials/>
          <Join/>
    </div>
  );
}

export default App;
