import React , {useRef} from "react";
import "./Join.css";
import emailjs from '@emailjs/browser';

const Join = () => {
    
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_rpd48ct', 'template_edbkivm', form.current, 'q9Qup48YPLn8683eN')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    }

    return(
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr/>
            <div>
                <span>Ready to</span>
                <span>Ready to</span>
            </div>
            <div>
                <span>Your Body</span>
                <span>Your Body</span>
            </div>
        </div>
        <div className="right-j">
            <form className="email-container" ref={form} onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder="Enter your Email" />
                <button className="btn btn-j">Join Now</button>
            </form>
        </div>
    </div>
    )
}

export default Join;